@media (max-width: 400px) {
  .mobile {
    display: flex;
    gap: 10px;
  }
  
  .text {
    flex: 1;
  }
}
