.auth-form {
    padding: 40px;
}

@media (max-width: 400px) {
    .account-button {
        border: none;
        background-color: unset !important;
        box-shadow: none;
        margin: 0 !important;
    }
}