.search-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    margin: 0 0 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border: none;
    }

    .text {
      margin: 0 0 10px;
    }

    a {
      color: #283018;
      text-decoration: underline;
      transition: .3s;

      &:hover {
        text-decoration: none;
      }
    }
  }
}