.impaired {
    height: 56px;
    background-color: #E4E5E0;
    color: #4A552E;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    gap: 6px;
}

.impairedWrapper {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 45px;
}

.impairedModal {
    height: 56px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid #E4E5E0 1px;
    width: 90%;
    background-color: #E4E5E0;
    overflow: hidden;
    gap: 2px;
    box-shadow: 0px 0px 8px 0px #00000029;
}

.impairedItem {
    height: 100%;
    flex: 1;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.impairedClose {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #F8D75E;
    position: absolute;
    bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0px 0px 8px 0px #00000029;
}