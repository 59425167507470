.App {
  text-align: center;
}

$n2: 18px;
$n2Title: 38px;
$n3Title: 30px;

$n3: 22px;
$n3Title: 42px;

body, html {
  min-height: 100%;
  height: auto;
  min-width: 370px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

#root {
  position: relative;
  height: 100%;
}

// =====MOBILE VERSION=====
@media (max-width: 400px) {

  body {
    background-color: white !important;
  }
  .br-12 {
    border-radius: 0 !important;
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  .w-240 {
    width: 100% !important;
  }
  .wrapper_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-bottom: 0;
    }
    .my_select {
      margin-bottom: 0 !important;
    }
  }

  .ant-form-item-label label {
    color: #768A46;
  }
  body .ant-modal-header .ant-modal-title {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 700;
  }
  .ant-modal-content {
    border-radius: 8px;
    position: relative;
    top: 20px;
  }
  .ant-modal-close {
    position: absolute;
    top: -20px;
    width: 40px;
    height: 40px;
    background-color: #F8D75E;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 20px;
      color: #4A552E;
    }
  }

  // Для динамического контента страниц
  .fadeIn {
    .table-overflow-wrapper {
      width: 100%;
      overflow-x: auto;
      table {
        td, table, thead, tbody {
          border-color: #828a7065 !important;
        }

        thead {
          background-color: #D9E4D1 !important;
          * {
            color: #828A70 !important;
            vertical-align: middle !important;
            // text-align: center !important;
          }
        }

        td span{
          font-size: 16px !important;
        }
        margin-left: 0 !important;
        // tr:first-child {
        //   span {
        //     color: #828A70 !important;
        //   }
        //   p {
        //     text-align: center !important;
        //   }
        //   background-color: #D9E4D1;
        //   color: #828A70;
        // }
      }
    }
    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .fadeIn-text {
      * {
        font-size: 18px !important;
        line-height: 24px;
        // font-weight: unset;
        text-align: left !important;
      
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
      }
      i {
        font-style: normal;
      }
      ul {
        padding-left: 30px;
      }
      a, a * {
        color: #768A46 !important;
        text-decoration: underline;
      }
      p {
        word-break: break-word;
      }
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    hr {
      opacity: 0;
    }
  }

  
  .default-padding-list {
    padding-top: 10px;
    list-style-type: none;
    padding-left: 0 !important;
    a {
      color: #768A46;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    li {
      margin-bottom: 10px;
      padding: 8px;
      background-color: white;
    }
  }

  .advertising-button {
    border-radius: 6px;
    background-color: #768A46;
    border-color: #768A46;
  }

  .home-title {
    color: #768A46 !important;
    font-size: 16px !important;
  }

  .home-box-news-title {
    padding-top: 16px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  .home-box-news-text {
    padding-bottom: 0;
  }
}

body {
  background-color: #1c170b;
  background-position: top center;
  background-repeat: no-repeat;

  .ant-modal-title {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  &#n2 {
    .title-1, .title, .ant-modal-header .ant-modal-title {
      font-size: $n2Title;
    }

    .ant-menu {
      font-size: $n2;
    }

    .ant-spin-container, .addition-maps-info, .ant-modal-body {
      font-size: $n2;
    }

    .box-text, .date {
      font-size: $n2;

      p, span {
        font-size: $n2 !important;
      }
    }

    .header-menu {
      ul {
        font-size: $n2;
      }
    }

    .header-title {
      font-size: $n2;
    }

    .ant-radio-button-wrapper {
      font-size: $n2;
    }

    .ant-modal-footer {
      button {
        font-size: $n2;
        line-height: 19px;
      }
    }

    .font-test {
      div {
        font-size: $n2;
      }

      button {
        font-size: $n2;
        line-height: normal;
      }
    }

    .home-news {
      .home-title {
        font-size: $n2Title;
      }
    }

    .home-box-news {

      h4 {
        font-size: $n2;
      }

      .home-box-news-text {
        font-size: $n2;
      }

      .home-box-news-date {
        font-size: 14px
      }
    }
  }

  &#n3 {
    .title-1, .title, .ant-modal-header .ant-modal-title {
      font-size: $n3Title;
    }

    .ant-menu {
      font-size: $n3;
    }

    .ant-spin-container, .addition-maps-info, .ant-modal-body {
      font-size: $n3;
    }

    .box-text, .date {
      font-size: $n3;

      p, span {
        font-size: $n3 !important;
      }

    }

    .header-menu {
      ul {
        font-size: $n3;
      }
    }

    .header-title {
      font-size: $n3;
    }

    .ant-radio-button-wrapper {
      font-size: $n3;
    }

    .ant-modal-footer {
      button {
        font-size: $n3;
        line-height: 19px;
      }
    }

    .font-test {
      div {
        font-size: $n3;
      }

      button {
        font-size: $n3;
        line-height: normal;
      }
    }

    .home-news {
      .home-title {
        font-size: $n3Title;
      }
    }

    .home-box-news {

      h4 {
        font-size: $n3;
      }

      .home-box-news-text {
        font-size: $n3;
      }

      .home-box-news-date {
        font-size: 14px
      }
    }
  }
}

.wrapper-animation {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;

  .ant-carousel, .slick-slider, .slick-list, .slick-track {
    height: 100%;
  }

  .slick-slide {
    display: inline-block;
    width: 100%;
    height: 100% !important;
    background-position: top center;
    background-size: cover;

    & > div {
      height: 100%;

      & > div, & > div > span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-position: top center;
        background-size: cover;
      }
    }
  }
}

@keyframes t7 {
  0% {
    opacity: 0;
  }
  14% {
    opacity: 0;
  }
  28% {
    opacity: 0;
  }
  42% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.transform-component-module_wrapper__1_Fgj {
  border: 1px solid #b1b1b1;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;

  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
}

.table-bordered {
  border: 1px solid #ccc;

  td, th {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;

    &:last-child {
      border-right: 1px solid #ccc;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgba(34, 46, 19, 0.93);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.br-12 {
  border-radius: 12px;
  overflow: hidden;
}

.no-points {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1140px;
}

.line {
  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #cd0000;
    border-radius: 6px;
  }

  @media screen and (max-width: 1199px) {
    &:before {
      display: none;
    }
  }
}


.header-title {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.header-title-none {
  @media screen and (min-width: 1199px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

.table-responsive {
  overflow: auto;
}

.hover-download {
  transition: .3s;

  &:hover {
    opacity: .7;
  }
}

.w-240 {
  width: 240px;
}

.w-270 {
  width: 270px;
}

.h-logo {
  height: 144px
}

.href {
  color: rgba(21, 28, 11, 0.78);
  text-decoration: underline;
  cursor: pointer;

  a {
    color: rgba(21, 28, 11, 0.78);
  }

  &:hover {
    text-decoration: none;
  }
}

.tableImg50 {
  td {
    width: 50% !important;

    img {
      width: 100% !important;
      min-height: 394px;
    }
  }
}

.h-500 {
  .ant-layout.ant-layout-has-sider {
    min-height: 500px !important;
  }

}

.default-padding-list {
  padding-left: 13px;
}

.color-1 {
  color: #000000D8
}

.icon-click {
  font-size: 40px;
  cursor: pointer
}

/*animation reload page*/
.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.relative {
  position: relative;
}

.fonts-loaded {
  background-color: red;
  background-image: none;
  font-family: "myfont", sans-serif;

  .font-test {
    font-size: 50px;
  }
}

.ant-layout-sider-zero-width-trigger {
  top: 0;
  background: #374a1c;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0;
}

.user_menu {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 20px;
}

.color-2 {
  background-color: #50682f;
  border-color: #67853d
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #454e2e;
}

.ant-tabs-tab:hover, .ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus {
  color: #454e2e;
}

.ant-tabs-ink-bar {
  background-color: #454e2e;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto;
  min-height: 40px;
}

.ant-menu-inline > .ant-menu-item, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto;
  min-height: 40px;
  line-height: normal;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 3px !important;
}


.ant-tag-new {
  white-space: initial !important;
  height: 42px;
  width: 200px;
  max-width: 150px;
  position: relative;
  text-align: center;
  line-height: normal;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.ant-modal-header {
  padding-right: 40px !important;
}

.search-trophies {
  .ant-btn-primary {
    background-color: #4A552E !important;
    border-color: #4A552E !important;
    box-shadow: 0 1px 4px 1px #E8EDE9;

    &:hover {
      opacity: .7;
    }
  }

  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    border-color: #4A552E;
    box-shadow: 0 0 0 2px rgba(74, 85, 46, 0.2);
  }
}

.required {
  &:before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}


.color_btn {
  color: #fff;
  background-color: #4A552E;
  border-color: #4A552E;
  box-shadow: 0 1px 4px 1px #E8EDE9;

  &:hover, &:focus, &:active {
    opacity: .7;
    background-color: #4A552E;
    border-color: #4A552E;
  }
}

/*table*/
.my_table {
  .ant-table {
    font-size: 12px !important;
  }

  .ant-table-thead > tr > th {
    color: #828A70 !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    padding: 8px 16px !important;
    background: #D9E4D1 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 2px 10px !important;
  }
}

.my_select {
  margin-bottom: 10px !important;

  &.ant-select {
    color: #4A552E !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px !important;
    border-radius: 4px !important;
    border: 1px solid #E7EDE3 !important;
    background: #FCFDFF !important;
    box-shadow: 0 0 6px 1px #EEF0F1 !important;
  }
}

.wrapper_filter {
  margin: 0 0 10px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #505a3c !important;
}

.ant-picker-active-bar {
  background: #505a3c !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #505a3c;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #6687244d;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
  background: #6687244d;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
  border-color: #6687244d !important;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  background: rgba(102, 135, 36, 0.7) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
  background: rgba(102, 135, 36, 0.7) !important;
}

.fz_12_title .ant-modal-header .ant-modal-title {
  font-size: 14px !important;
  line-height: normal !important;
}

.ant-layout-sider-zero-width {
  .bottom_info_menu {
    display: none;
  }
}

.view {
  width: 100%;
}

.view_PDF {
  overflow: auto;
  height: 700px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.myBtn {
  background-color: #50682f;
  border-color: #67853d
}

.note-video-clip {
  width: 100% !important;
  height: 500px !important;
}

.security-policy {
  font-size: 10px
}


@import "style/sizeFont";
