.header-menu {
  .ant-menu-horizontal {
   line-height: normal;
  }
  .ant-menu-submenu-title {
    color: #fff !important;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    font-size: 14px;

    li {
      display: inline-block;
      list-style: none;
      margin: 0 5px;
      padding: 0  !important;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }

      &:before, &:after {
        display: none !important;
      }

      &:hover {
        a {
          &.active, &:hover {
            color: #cd0000 !important;
          }
        }
      }

      a {
        color: #fff !important;
        text-transform: uppercase;
        text-decoration: none;

        &.active {
          color: #cd0000 !important;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .header-menu {
    height: 48px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    ul li a {
      color: #59653C !important;
      text-transform: unset;
    }
  }
}