.ant-layout-sider {
  padding: 30px 15px;
  background-color: #f7f7f7;
}

.ant-layout-content {
  background-color: #fff;
}

.ant-menu {
  border:none;
  background-color: transparent;
}

.ant-menu-submenu-selected {
  color: rgb(51, 72, 26);
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
  color: rgb(51, 72, 26);
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  min-height: 40px;
  line-height: normal;
}

.ant-menu-item a:hover {
  color: #888;
}

.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #888;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #505b3c3b;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #505a3c;
  color: #fff;
  a {
    color: #fff;
  }
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  border-color: #3b432c;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #fff;
}

//menu

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title {
  height: auto;
  line-height: 20px;
  margin-bottom: 4px;
  margin-top: 4px;
  overflow: visible;
  padding: 8px 16px;
  text-overflow: initial;
  word-wrap: break-word;
}

.ant-menu-item, .ant-menu-submenu-title {
  white-space: normal;
}

//.ant-empty.ant-empty-normal,.ant-spin.ant-spin-spinning {
//  position: absolute;
//  left: 50%;
//  top: 50%;
//  margin: 0;
//  transform: translate(-50%, -50%);
//}


@media screen and (max-width: 991px) {
  .ant-layout-sider {
    .ant-layout-sider-children > ul {
      height: 500px !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 400px) {
  .ant-menu {
    padding: 16px;
    background-color: #F5F5F5;
  }
  .ant-menu-item {
    min-height: 48px !important;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-left: 16px !important;
    a {
      color: #4A552E;
      font-size: 16px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #768A46;
  }
  .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
    border: none;
  }

  .ant-menu-submenu-title {
    min-height: 48px !important;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-left: 16px !important;
    padding-right: 40px !important;
    span {
      color: #4A552E;
      font-size: 16px;
    }
  }

  .ant-menu-submenu-open {
    background-color: #768A46 !important;
    border-radius: 8px;
    .ant-menu-submenu-title {
      width: 100%;
      background-color: #768A46 !important;
      span {
        color: #FFFFFF;
      }
      .ant-menu-submenu-arrow {
        color: #FFFFFF !important;
      }
    }
  }
  
  .ant-menu-submenu {
    border-radius: 8px;
    .ant-menu-sub {
      background-color: #768A46;
      border-radius: 8px;
    }
    .ant-menu-item {
      height: 40px !important;
      width: auto;
      background-color: #768A46;
      margin: 0 !important;
      span {
        height: 40px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        border-left: solid 4px #768A46;
        border-radius: 4px;
      }
      a {
        color: #FFFFFF;
      }
    }

    .ant-menu-item-selected {
      span {
       display: flex;
       align-items: center;
       background-color:#FFFFFF14 !important;
       border-left: solid 4px #FFFFFF !important;
       border-radius: 4px;
      } 
     }
  }

  .ant-layout-content > div {
    background-color: #F5F5F5;
  }

  .header-menu  .ant-menu-item {
    display: flex;
    align-items: center;
    a {
      color: #59653C;
      font-weight: bold;
    }
  }

  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}