.ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ant-drawer-header {
    display: none;
  }

.box-mobile-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
}

.box-mobile-header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #59653C;
    padding-left: 16px;
}

.box-mobile-buttons {
    display: flex;
    align-items: center;
}

.box-mobile-logo {
    height: 48px;
}

.box-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 56px;
    height: 56px;
    color: white;
}

.box-mobile-button-close {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #F8D75E;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    top: 65px;
    left: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.box-mobile-title {
    height: 105px;
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #4A552E;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: white;
    text-transform: uppercase;
}

.box-mobile-item {
    min-height: 46px;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.box-mobile-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #4A552E;
    font-size: 18px;
    font-weight: 600;
    &:hover {
        color: #4A552E !important;
    }
}

.box-mobile-link.active {
    color:#cd0000 !important;
}

.box-mobile-club {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px rgb(228, 229, 224) !important;
    border-bottom: solid 1px rgb(228, 229, 224) !important;
    padding: 16px 16px 16px 0;
}

.top-menu {
    height: 48px;
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: auto;
    padding: 0 16px;
    border-bottom: solid #E4E5E0 1px;
    scrollbar-width: none;
    background-color: #FFFFFF;
}

.top-menu-nav {
    a {
        display: block;
        width: max-content;
        color: #59653C;
        font-weight: bold;
    }
    .active {
        color: #cd0000 !important;
    }
}

.box-mobile-route-panel {
    color: #768A46;
    padding: 6px 16px;
    background-color: #FFFFFF;
    border-bottom: solid #E4E5E0 1px;
}

@media(max-width: 400px) {
        .ant-radio-button-wrapper {
            span {
                font-size: 14px !important;
            }
        }
}